import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function InstRoutes() {
  let element = useRoutes(MainRoutes);
  return element;
}
