import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const PostForm = SuspenseWrapper(
  lazy(() => import("components/posts/v2/PostForm"))
);
const StaffRoom = SuspenseWrapper(
  lazy(() => import("components/posts/v2/OverAllView"))
);

const StaffRoomRoutes = {
  path: "staff-room",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <StaffRoom mode="R"  tab=""/>,
    },
    {
      path: "add",
      element: <PostForm pageMode="R" tab=""/>,
    },
  ],
};

export default StaffRoomRoutes;
