import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const LeaveMasterList = SuspenseWrapper(
  lazy(() => import("components/leave-management/MasterList"))
);
const LeavePolicyList = SuspenseWrapper(
  lazy(() => import("components/leave-management/PolicyList"))
);
const LeavePolicyData = SuspenseWrapper(
  lazy(() => import("components/leave-management/PolicyDataForm"))
);
const LeavePolicyDataList = SuspenseWrapper(
  lazy(() => import("components/leave-management/PolicyDataList"))
);
const LeaveCreditList = SuspenseWrapper(
  lazy(() => import("components/leave-management/CreditList"))
);
const LeaveDebitList = SuspenseWrapper(
  lazy(() => import("components/leave-management/DebitList"))
);
const LeaveSummaryList = SuspenseWrapper(
  lazy(() => import("components/leave-management/SummaryList"))
);
const LeaveManagementRoutes = {
  path: "leave-management",
  element: <Outlet />,
  children: [
    {
      path: "master-list",
      element: <LeaveMasterList />,
    },
    {
      path: "policy-list",
      element: <LeavePolicyList />,
    },
    {
      path: "policy-data",
      element: <Outlet />,
      children: [
        {
          path: "list/:hash",
          element: <LeavePolicyDataList />,
        },
        {
          path: "add/:hash",
          element: <LeavePolicyData />,
        }, 
        {
          path: "edit/:dataid",
          element: <LeavePolicyData />,
        },
      ],
    },
    {
      path: "credit-list",
      element: <LeaveCreditList />,
    }, 
    {
      path: "debit-list",
      element: <LeaveDebitList />,
    }, 
    {
      path: "summary-list",
      element: <LeaveSummaryList />,
    },
  ],
 
};

export default LeaveManagementRoutes;
