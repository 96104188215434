import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const SubjectBookForm = SuspenseWrapper(
  lazy(() => import("components/less-plan/SubjectBookForm"))
);
const SubjectBookList = SuspenseWrapper(
  lazy(() => import("components/less-plan/SubjectBookList"))
);
const BookTopicForm = SuspenseWrapper(
  lazy(() => import("components/less-plan/BookTopicForm"))
);
const BookTopicList = SuspenseWrapper(
  lazy(() => import("components/less-plan/BookTopicList"))
);
const BookMaterial = SuspenseWrapper(
  lazy(() => import("components/less-plan/BookMaterialOverAllView"))
);
const LessonPlanProgress = SuspenseWrapper(
  lazy(() => import("components/less-plan-progress/OverAllView"))
);
const ProgressForm = SuspenseWrapper(
  lazy(() => import("components/less-plan-progress/ProgressForm"))
);
const ProgressReport = SuspenseWrapper(
  lazy(() => import("components/less-plan-progress/ProgressReport"))
);
const SubjectImportForm = SuspenseWrapper(
  lazy(() => import("components/less-plan/SubjectImportForm"))
);
const SubjectImportList = SuspenseWrapper(
  lazy(() => import("components/less-plan/SubjectImportList"))
);
const SubjectImportStatusList = SuspenseWrapper(
  lazy(() => import("components/less-plan/SubjectImportStatusList"))
);

const LessonPlanRoutes = {
  path: "lessonplan",
  element: <Outlet />,
  children: [
    {
      path: "subject-book",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <SubjectBookList />,
        },
        {
          path: "add",
          element: <SubjectBookForm />,
        },
      ],
    },
    {
      path: "book-topic",
      element: <Outlet />,
      children: [
        {
          path: "list/:hash",
          element: <BookTopicList />,
        },
        {
          path: "add/:hash",
          element: <BookTopicForm />,
        },
        {
          path: "import-list",
          element: <SubjectImportList />,
        },
        {
          path: "import-add",
          element: <SubjectImportForm />,
        },
        {
          path: "import-status/:hash",
          element: <SubjectImportStatusList />,
        },
      ],
    },
    {
      path: "book-material",
      element: <Outlet />,
      index: true,
      element: <BookMaterial mode="P" />,
    },
    {
      path: "progress",
      element: <Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LessonPlanProgress mode="P" />,
        },
        {
          path: "add",
          element: <ProgressForm />,
        },
        {
          path: "edit/:hash",
          element: <ProgressForm />,
        },
      ],
    },
    {
      path: "report",
      element: <Outlet />,
      index: true,
      element: <ProgressReport mode="P" />,
    },
  ],
};

export default LessonPlanRoutes;
