import { useEffect, useRef, useState } from 'react';

export const useStorageSync = (key) => {
    const [value, setValue] = useState(localStorage.getItem(key));
    const lastValueRef = useRef(value);
    const broadcast = new BroadcastChannel('localStorageChannel');

    const setLocalStorage = (newValue) => {
        setValue(newValue); // Update state with the new value

        // Send message via BroadcastChannel
        broadcast.postMessage({ key, newValue });
    };

    useEffect(() => {
        const handleBroadcastMessage = (event) => {
            const { key: eventKey, newValue } = event.data;
            if (eventKey === key && newValue !== lastValueRef.current) {
                lastValueRef.current = newValue;
                // Check if the document is hidden (current tab doesn't have focus)
                if (document.hidden) {
                    // If document is hidden, reload the current tab
                    window.location.reload(); // Reload the current tab
                }
            }
        };

        // Listen for messages on the broadcast channel
        broadcast.addEventListener('message', handleBroadcastMessage);

        return () => {
            broadcast.removeEventListener('message', handleBroadcastMessage);
            broadcast.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    return { value, setValue: setLocalStorage };
};

export default useStorageSync;
