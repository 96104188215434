import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const EventTypeList = SuspenseWrapper(
  lazy(() => import("components/calendar/EventTypeList"))
);
// const EventTypeForm = SuspenseWrapper(
//   lazy(() => import("components/calendar/EventTypeForm"))
// );
const EventList = SuspenseWrapper(
  lazy(() => import("components/calendar/EventList"))
);
const EventForm = SuspenseWrapper(
  lazy(() => import("components/calendar/EventForm"))
);

const CalendarRoutes = {
  path: "calendar",
  element: <Outlet />,
  children: [
    {
      path: "event-type",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <EventTypeList />,
        },
        // Add more routes for event-type if needed
      ],
    },
    {
      path: "event",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <EventList />,
        },
        {
          path: "add",
          element: <EventForm />,
        },
        {
          path: "edit/:hash",
          element: <EventForm />,
        },
      ],
    },
  ],
};

export default CalendarRoutes;
