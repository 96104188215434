import { Suspense } from "react";
import PageLoader from "components/common/PageLoader";

// ==============================|| SuspenseWrapper - LAZY LOADING ||============================== //

const SuspenseWrapper = (Component) => (props) =>
  (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default SuspenseWrapper;
