import { lazy, Suspense, useMemo } from "react";

const Lavender = lazy(() => import("themes/Lavender"));
const Blue = lazy(() => import("themes/Blue"));
const ClassPep = lazy(() => import("themes/ClassPep"));

const themes = {
  lavender: (children) => (
    <Suspense fallback={"Loading ..."}>
      <Lavender>{children}</Lavender>
    </Suspense>
  ),
  blue: (children) => (
    <Suspense fallback={"Loading ..."}>
      <Blue>{children}</Blue>
    </Suspense>
  ),
  classpep: (children) => (
    <Suspense fallback={"Loading ..."}>
      <ClassPep>{children}</ClassPep>
    </Suspense>
  ),
};
function ThemeWrapper({ children }) {
  /*const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const ctheme = useMemo(() => {
      //theme.palette.mode = prefersDarkMode;
      return theme;
    }, [prefersDarkMode]);*/

  const themeName = useMemo(() => {
    return localStorage.getItem("theme") || "classpep";
  }, []);

  return (
    <>
      {themes[themeName] !== undefined &&
        themes[themeName] !== null &&
        themes[themeName](children)}
      {(themes[themeName] === undefined || themes[themeName] === null) && (
        <Suspense fallback={"Loading ..."}>
          <Lavender>{children}</Lavender>
        </Suspense>
      )}
    </>
  );
}

export default ThemeWrapper;
