import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const instExclusiveMenuList = createSlice({
  name: "instExclusiveMenuList",
  initialState,
  reducers: {
    setInstExclusiveMenuList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setInstExclusiveMenuList } = instExclusiveMenuList.actions;
export default instExclusiveMenuList.reducer;
