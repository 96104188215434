import { Box, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import * as serviceWorker from "serviceWorkerRegistration";

const Reload = ({ children }) => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);
  
    const onSWUpdate = (registration) => {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    };
  
    useEffect(() => {
      serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);
  
    const reloadPage = () => {
      waitingWorker?.postMessage({ type: "SKIP_WAITING" });
      setShowReload(false);
      window.location.reload(true);
    };

    return (
        <>
            {children}
            <Snackbar
                open={showReload}
                message={
                    <span style={{ color: "#ffffff", fontWeight: "bold" }}>
                       The New Version is Available Please!
                    </span>
                }
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={reloadPage}
                        sx={{
                            border: "1px solid #ffffff",
                            borderRadius: 2,
                            padding: "5px 10px",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#ffffff",
                                color: "#4ca7f6",
                            },
                        }}
                    >
                        Refresh
                    </Button>
                }
                sx={{
                    "& .MuiSnackbarContent-root": {
                        backgroundColor: "#4ca7f6",
                        boxShadow: 3,
                        borderRadius: 2,
                        animation: "fade-in 0.5s ease-in-out",
                        "@keyframes fade-in": {
                            from: { opacity: 0 },
                            to: { opacity: 1 },
                        },
                    },
                }}
            />
        </>
    );
};

export default Reload;
