import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const VoiceCallCategoryList = SuspenseWrapper(
  lazy(() => import("components/voice-call/VoiceCallCategoryList"))
);
const ScholarVoiceCall = SuspenseWrapper(
  lazy(() => import("components/voice-call/list/OverAllView"))
);
const StaffVoiceCall = SuspenseWrapper(
  lazy(() => import("components/voice-call/list/OverAllView"))
);
const SmsDeliveryList = SuspenseWrapper(
  lazy(() => import("components/sms/send/SmsDeliveryList"))
);
const VoiceCallCreditReport = SuspenseWrapper(
  lazy(() => import("components/voice-call/reports/CreditReport"))
);
const VoiceCallDebitReport = SuspenseWrapper(
  lazy(() => import("components/voice-call/reports/DebitReport"))
);

const VoiceCallRoutes = {
  path: "voice-call",
  element: <Outlet />,
  children: [
    {
      path: "category-list",
      element: <VoiceCallCategoryList />,
    },
    {
      path: "list",
      element: <ScholarVoiceCall mode="P" />,
    },
    {
      path: "staff-list",
      element: <StaffVoiceCall mode="R" />,
    },
    {
      path: "delivery/:hash",
      element: <SmsDeliveryList mode="P" />,
    },
    {
      path: "staff-delivery/:hash",
      element: <SmsDeliveryList mode="R" />,
    },
    {
      path: "credit-report",
      element: <VoiceCallCreditReport />,
    },
    {
      path: "debit-report",
      element: <VoiceCallDebitReport />,
    },
  ],
};

export default VoiceCallRoutes;
