// 
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const dashboardAccessSlice = createSlice({
  name: "dashboardAccess",
  initialState,
  reducers: {
    setDashboardAccess: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setDashboardAccess } = dashboardAccessSlice.actions;
export default dashboardAccessSlice.reducer;
