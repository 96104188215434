import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ActivityList = SuspenseWrapper(
  lazy(() => import("components/activity/OverAllView"))
);
const ActivityForm = SuspenseWrapper(
  lazy(() => import("components/activity/AddHomeWork"))
);
const ActivityView = SuspenseWrapper(
  lazy(() => import("components/activity/ActivityView"))
);
const ActivityDayStatusForm = SuspenseWrapper(
  lazy(() => import("components/activity/ActivityDayStatusForm"))
);

const AddSimpleHomeWork = SuspenseWrapper(
  lazy(() => import("components/activity/AddSimpleHomeWork"))
);

const AddSimpleSettings = SuspenseWrapper(
  lazy(() => import("components/activity/ActivitySimpleSettings"))
);
const AddSimpleList = SuspenseWrapper(
  lazy(() => import("components/activity/simplehomework/OverAllView"))
);

const ActivityRoutes = {
  path: "activity",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <ActivityList tab="" />,
    },
    {
      path: "add",
      element: <ActivityForm tab="" />,
    },
    {
      path: "view/:hash",
      element: <ActivityView />,
    },
    {
      path: "dayStatus",
      element: <ActivityDayStatusForm tab=""/>,
    },
    {
      path: "simple-homework",
      element: <AddSimpleHomeWork tab=""/>,
    },
    {
      path: "simple-settings",
      element: <AddSimpleSettings tab=""/>,
    },
    {
      path: "simplelist",
      element: <AddSimpleList />,
    },
  ],
};

export default ActivityRoutes;
