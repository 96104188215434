import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";
const ExamGradeSystemList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGradeSystemList"))
);
const ExamGradeScaleList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGradeScaleList"))
);
const ExamGroupNameList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGroupNameList"))
);
const ExamGroupNameForm = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGroupNameForm"))
);
const ExamGroupList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGroupList"))
);
const ExamGroupForm = SuspenseWrapper(
  lazy(() => import("components/exam/ExamGroupForm"))
);
const ExamAssessmentNameList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamAssessmentNameList"))
);
const ExamAssessment = SuspenseWrapper(
  lazy(() => import("components/exam/assessment/OverAllView"))
);
const ExamAssessmentDetail = SuspenseWrapper(
  lazy(() => import("components/exam/assessment/SingleView"))
);
const ExamMarkEntryList = SuspenseWrapper(
  lazy(() => import("components/exam/mark-entry/OverAllView"))
);
const ExamClassScholarReport = SuspenseWrapper(
  lazy(() => import("components/exam/class-scholar-report/OverAllView"))
);
const ExamClassAnalysisReport = SuspenseWrapper(
  lazy(() => import("components/exam/class-analysis-report/OverAllView"))
);
const ExamMarkEntryGrid = SuspenseWrapper(
  lazy(() => import("components/exam/mark-entry-grid/DataGrid"))
);
const ExamReportList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamReportList"))
);
const ExamReportEditForm = SuspenseWrapper(
  lazy(() => import("components/exam/ExamReportEditForm"))
);
const ExamAssessmentMarkEntryList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamAssessmentMarkEntryList"))
);
const ExamAssessmentStatusList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamAssessmentStatusList"))
);
const ExamReportSettingForm = SuspenseWrapper(
  lazy(() => import("components/exam/ExamReportSettingForm"))
);
const ExamSettingCopyForm = SuspenseWrapper(
  lazy(() => import("components/exam/ExamSettingCopyForm"))
);
const ExamMarkAsSmsList = SuspenseWrapper(
  lazy(() => import("components/exam/ExamMarkAsSmsList"))
);
const ExamOverAllReport = SuspenseWrapper(
  lazy(() => import("components/exam/ExamOverallReport"))
);
const StudentMarkEntryGrid = SuspenseWrapper(
  lazy(() => import("components/exam/mark-entry-grid/StudentDataGrid"))
);
const ExamRoutes = {
  path: "exam",
  element: <Outlet />,
  children: [
    {
      path: "grade-system/list",
      element: <ExamGradeSystemList />,
    },
    {
      path: "grade-scale/list/:hash",
      element: <ExamGradeScaleList />,
    },
    {
      path: "exam-name/list",
      element: <ExamGroupNameList />,
    },
    {
      path: "exam-name/add",
      element: <ExamGroupNameForm />,
    },
    {
      path: "exam-name/edit/:hash",
      element: <ExamGroupNameForm />,
    },
    {
      path: "assessment-name/list",
      element: <ExamAssessmentNameList />,
    },
    {
      path: "assessment/list",
      element: <ExamAssessment />,
    },
    {
      path: "assessment/:hash",
      element: <ExamAssessmentDetail />,
    },
    {
      path: "exam-group/list",
      element: <ExamGroupList />,
    },
    {
      path: "exam-group/add",
      element: <ExamGroupForm />,
    },
    {
      path: "exam-group/edit/:hash",
      element: <ExamGroupForm />,
    },
    {
      path: "mark-entry/list",
      element: <ExamMarkEntryList />,
    },
    {
      path: "mark-entry-grid/:hash",
      element: <ExamMarkEntryGrid />,
    },
    {
      path: "class-scholar-report",
      element: <ExamClassScholarReport />,
    },
    {
      path: "class-analysis-report",
      element: <ExamClassAnalysisReport />,
    },
    {
      path: "exam-reports/list",
      element: <ExamReportList />,
    },
    {
      path: "exam-reports/edit/:hash",
      element: <ExamReportEditForm />,
    },
    {
      path: "exam-assessment-markentry/list",
      element: <ExamAssessmentMarkEntryList />,
    },
    {
      path: "mark-entry/list/:hash",
      element: <ExamMarkEntryList />,
    },
    {
      path: "exam-assessment-status/list",
      element: <ExamAssessmentStatusList />,
    },
    {
      path: "report-setting/add/:hash",
      element: <ExamReportSettingForm />,
    },
    {
      path: "exam-setting/copy/:hash",
      element: <ExamSettingCopyForm />,
    },
    {
      path: "mark-as-sms/list",
      element: <ExamMarkAsSmsList />,
    },
    {
      path: "mark-overall-report",
      element: <ExamOverAllReport />,
    },
    {
      path: "student-mark-entry-grid/:hash",
      element: <StudentMarkEntryGrid />,
    },
  ],
};

export default ExamRoutes;
