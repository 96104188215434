import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ExitReasonList = SuspenseWrapper(
  lazy(() => import("components/scholar-exit/ExitReasonList"))
);
const ExitList = SuspenseWrapper(lazy(() => import("components/scholar-exit/ExitList")));
const ExitForm = SuspenseWrapper(lazy(() => import("components/scholar-exit/ExitForm")));

const ScholarExitRoutes = {
  path: "scholar-exit",
  element: <Outlet />,
  children: [
    {
      path: "reason",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ExitReasonList />,
        },
      ],
    },
    {
      path: "exit",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ExitList />,
        },
        {
          path: "add",
          element: <ExitForm />,
        },
        {
          path: "edit/:hash",
          element: <ExitForm />,
        },
      ],
    },
  ],
};

export default ScholarExitRoutes;
