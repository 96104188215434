import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ResourceMasterList = SuspenseWrapper(
  lazy(() => import("components/resource/ResourceMasterList"))
);
const ResourceMasterAdd = SuspenseWrapper(
  lazy(() => import("components/resource/ResourceMasterForm"))
);
const ResourceTopicAdd = SuspenseWrapper(
  lazy(() => import("components/resource/ResourceTopicForm"))
);
const ResourceContentAdd = SuspenseWrapper(
  lazy(() => import("components/resource/ResourceContentForm"))
);
const ResourceContentView = SuspenseWrapper(
  lazy(() => import("components/resource/ResourceContentView"))
);

const ResourceRoutes = {
  path: "resource",
  element: <Outlet />,
  children: [
    {
      path: "master/list",
      element: <ResourceMasterList />,
    },
    {
      path: "master/add",
      element: <ResourceMasterAdd />,
    },
    {
      path: "topic/add",
      element: <ResourceTopicAdd />,
    },
    {
      path: "content/add/:hash",
      element: <ResourceContentAdd />,
    },
    {
      path: "content/view/:hash",
      element: <ResourceContentView />,
    },
  ],
};

export default ResourceRoutes;
