import React, { lazy } from "react";
import SuspenseWrapper from "routes/SuspenseWrapper";

const Dashboard = SuspenseWrapper(
  lazy(() => import("components/dashboard-v2/OverView"))
);

const DashboardRoutes = {
  path: "dashboard",
  element: <Dashboard />,
};

export default DashboardRoutes;
