import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import SuspenseWrapper from "./SuspenseWrapper";
import ComponentRoutes from "./componentRoutes";

const UnderMaintenancePage = SuspenseWrapper(
  lazy(() => import("components/UnderMaintenance"))
);
const App = SuspenseWrapper(lazy(() => import("../App")));
const InstLayout = SuspenseWrapper(lazy(() => import("components/InstLayout")));

const MainRoutes = [
  { index: true, element: <Navigate to="/institution-list" /> },
  {
    path: "under-maintenance",
    element: <UnderMaintenancePage />,
  },
  {
    path: "institution-list",
    element: <App />,
  },
  {
    path: "/",
    element: <InstLayout />,
    children: ComponentRoutes,
  },
  {
    path: "/*",
    element: <Navigate to="/institution-list" />,
  },
];

export default MainRoutes;
