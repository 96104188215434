import { createSlice } from '@reduxjs/toolkit';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Api from "../ajax";

const api = new Api();

const language = createSlice({
  name: 'language',
  initialState: {
    list: [],
  },
  reducers: {
    setLanguageList(state, action) {
      state.list = action.payload;
    },
  },
});

export const useLanguage = () => {
  const dispatch = useDispatch();
  const languageList = useSelector((state) => { return state.language.list });

  useEffect(() => {
    if (!languageList.length) {
      api.post("/language/get-all", {}, (res) => {
        dispatch(setLanguageList(res.d.list));
      });
    }
  }, [dispatch, languageList.length]);

  return languageList;
}

export const { setLanguageList } = language.actions;
export default language.reducer;