import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const KnowledgeMasterList = SuspenseWrapper(
  lazy(() => import("components/knowledgehub/KnowledgeMasterList"))
);
const KnowledgeContentView = SuspenseWrapper(
  lazy(() => import("components/knowledgehub/KnowledgeContentView"))
);
const ContentDetails = SuspenseWrapper(lazy(() => import("components/knowledgehub/ContentDetails")));


const KnowledgehubRoutes = {
  path: "knowledgehub",
  element: <Outlet />,
  children: [
    {
      path: "master/list",
      element: <KnowledgeMasterList />,
    },
    {
      path: "content/view/:hash",
      element: <KnowledgeContentView />,
    },
    {
      path: "content-detail/:id",
      element: <ContentDetails/>,
    },
  ],
};

export default KnowledgehubRoutes;
