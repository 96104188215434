import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TaskStatus = SuspenseWrapper(lazy(() => import("components/worktodo/TaskStatus")));
const TaskBoard = SuspenseWrapper(lazy(() => import("components/worktodo/TaskBoard")));
const TaskTodo = SuspenseWrapper(lazy(() => import("components/worktodo/TaskTodo")));
const TaskTodoForm = SuspenseWrapper(
  lazy(() => import("components/worktodo/TaskTodoForm"))
);

const TodoWorkRoutes = {
  path: "todowork",
  element: <Outlet />,
  children: [
    {
      path: "taskStatus",
      element: <TaskStatus />,
    },
    {
      path: "taskBoard",
      element: <TaskBoard />,
    },
    {
      path: "tasktodo",
      element: <TaskTodo />,
    },
    {
      path: "tasktodo/add",
      element: <TaskTodoForm />,
    },
    {
      path: "tasktodo/edit/:hash",
      element: <TaskTodoForm />,
    },
  ],
};

export default TodoWorkRoutes;
