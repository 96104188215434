// 
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const roleActionDisableSlice = createSlice({
  name: "roleActionDisable",
  initialState,
  reducers: {
    setRoleActionDisable: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRoleActionDisable } = roleActionDisableSlice.actions;
export default roleActionDisableSlice.reducer;
