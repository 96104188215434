import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TeacherCornerPosts = SuspenseWrapper(lazy(() => import("components/teachercorner/OverAllView")));
const TeacherCornerCatList = SuspenseWrapper(lazy(() => import("components/teachercorner/CategoryList")));
const TeacherCornerPostForm = SuspenseWrapper(lazy(() => import("components/teachercorner/PostForm")));

const TeacherCornerRoutes = {
  path: "teacher-corner",
  element: <Outlet />,
  children: [
    {
      path: "list",
      index: true,
      element: <TeacherCornerPosts/>,
    },
    {
      path: "add",
      element: <TeacherCornerPostForm/>,
    },
    {
      path: "edit/:hash",
      element: <TeacherCornerPostForm />,
    },
    {
      path: "category/list",
      element: <TeacherCornerCatList />,
    },

  ],
};

export default TeacherCornerRoutes;
