import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StaffCommunication = SuspenseWrapper(lazy(() => import("components/communication/StaffCommunication")));
const PostForm=SuspenseWrapper(lazy(() => import("components/posts/v2/PostForm")))
const SmsForm = SuspenseWrapper(lazy(() => import("components/sms/send-v4/SmsForm")));
const WhatsappForm = SuspenseWrapper(lazy(() => import("components/whatsapp/send/WhatsappForm")));

const StaffCommunicationRoutes = {
  path: "staff-com",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <StaffCommunication  pageMode = "R" tab = "S"/>
        </Suspense>
      ),
    },
    {
      path: "staff-room",
      element: <Outlet />,
      children: [
        {
          path: "add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <PostForm pageMode = "R" tab = "S"/>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "sms",
      element: <Outlet />,
      children: [
        {
          path: "staff-add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <SmsForm pageMode = "R" tab = "S"/>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "whatsapp",
      element: <Outlet />,
      children: [
        {
          path: "staff-add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <WhatsappForm  pageMode = "R" tab = "S"/>
            </Suspense>
          ),
    
        },
      ]
    }
  ],
};
export default StaffCommunicationRoutes;
