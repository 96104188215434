import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TransportCategoryList = SuspenseWrapper(
  lazy(() => import("components/transport/CategoryList"))
);
const TransportCompanyList = SuspenseWrapper(
  lazy(() => import("components/transport/CompanyList"))
);
const TransportBrandList = SuspenseWrapper(
  lazy(() => import("components/transport/BrandList"))
);
const TransportVehicleList = SuspenseWrapper(
  lazy(() => import("components/transport/VehicleList"))
);
const TransportVehicleForm = SuspenseWrapper(
  lazy(() => import("components/transport/VehicleForm"))
);
const TransportDocumentTypeList = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentTypeList"))
);
const TransportDocumentList = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentList"))
);
const TransportDocumentForm = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentForm"))
);
const TransportStageList = SuspenseWrapper(
  lazy(() => import("components/transport/StageList"))
);
const TransportStopList = SuspenseWrapper(
  lazy(() => import("components/transport/StopList"))
);
const TransportRouteList = SuspenseWrapper(
  lazy(() => import("components/transport/RouteList"))
);
const TransportRouteStopList = SuspenseWrapper(
  lazy(() => import("components/transport/RouteStopList"))
);
const TransportScholarList = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarList"))
);
const TransportScholarForm = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarForm"))
);
const TransportScholarExit = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarExitForm"))
);
const TransportScholarRevert = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarRevertForm"))
);

const TransportRoutes = {
  path: "transport",
  element: <Outlet />,
  children: [
    {
      path: "category-list",
      element: <TransportCategoryList />,
    },
    {
      path: "company-list",
      element: <TransportCompanyList />,
    },
    {
      path: "brand-list",
      element: <TransportBrandList />,
    },
    {
      path: "vehicle",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportVehicleList />,
        },
        {
          path: "add",
          element: <TransportVehicleForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportVehicleForm />,
        },
      ],
    },
    {
      path: "doctype-list",
      element: <TransportDocumentTypeList />,
    },
    {
      path: "document-list",
      element: <TransportDocumentList />,
    },
    {
      path: "document",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportDocumentList />,
        },
        {
          path: "add",
          element: <TransportDocumentForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportDocumentForm />,
        },
      ],
    },
    {
      path: "stage-list",
      element: <TransportStageList />,
    },
    {
      path: "stop-list",
      element: <TransportStopList />,
    },
    {
      path: "route-list",
      element: <TransportRouteList />,
    },
    {
      path: "routestop-list",
      element: <TransportRouteStopList />,
    },
    {
      path: "scholar",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportScholarList />,
        },
        {
          path: "add",
          element: <TransportScholarForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportScholarForm />,
        },
        {
          path: "view/:hash",
          element: <TransportScholarExit />,
        },
        {
          path: "revert/:hash",
          element: <TransportScholarRevert />,
        },
      ],
    },
  ],
};

export default TransportRoutes;
