import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const AbsentList = SuspenseWrapper(
  lazy(() => import("components/dashboard-v2/studentAbsentList"))
);
const AbsentListSectionWise = SuspenseWrapper(
  lazy(() => import("components/dashboard-v2/StudentAbsentListSectionWise"))
);
const DashboardAttendanceOverallView = SuspenseWrapper(
  lazy(() => import("components/dashboard-v2/DashboardAttendanceOverallView"))
);

const StudentAttendanceRoutes = {
  path: "attendance",
  element: <Outlet />,
  children: [
    {
      path: "absentList",
      index: true,
      element: <AbsentList />,
    },
    {
      path: "absentlist-sec-wise",
      index: true,
      element: <AbsentListSectionWise />,
    },
    {
      path: "dashboard-absentlist",
      index: true,
      element: <DashboardAttendanceOverallView />,
    },
  ],
};

export default StudentAttendanceRoutes;
