import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const RoleList = SuspenseWrapper(lazy(() => import("components/security/RoleList")));
const StaffRoleList = SuspenseWrapper(
  lazy(() => import("components/security/StaffRoleList"))
);
const StaffMenuList = SuspenseWrapper(
  lazy(() => import("components/security/staff-menu/OverAllView"))
);
const StaffMenuPermissionForm = SuspenseWrapper(
  lazy(() => import("components/security/staff-menu/PermissionForm"))
);
const RoleMenuList = SuspenseWrapper(
  lazy(() => import("components/security/role-menu/OverAllView"))
);
const RoleMenuPermissionForm = SuspenseWrapper(
  lazy(() => import("components/security/role-menu/PermissionForm"))
);

const RoleDashboardList = SuspenseWrapper(
  lazy(() => import("components/security/role-dashboard/RoleDashboardList"))
);
const StaffDashboardList = SuspenseWrapper(
  lazy(() => import("components/security/staff-dashboard/StaffDashboardList"))
);

const SecurityRoutes = {
  path: "security",
  element: <Outlet />,
  children: [
    {
      path: "role",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <RoleList />,
        },
        {
          path: "menu-list",
          element: <RoleMenuList />,
        },
        {
          path: "menu-edit/:hash",
          element: <RoleMenuPermissionForm />,
        },
        {
          path: "dashboard-list",
          element: <RoleDashboardList />,
        },
      ],
    },
    {
      path: "staff",
      element: <Outlet />,
      children: [
        {
          path: "menu-list",
          element: <StaffMenuList />,
        },
        {
          path: "menu-edit/:hash",
          element: <StaffMenuPermissionForm />,
        },
        {
          path: "role-list",
          element: <StaffRoleList />,
        },
        {
          path: "dashboard-list",
          element: <StaffDashboardList />,
        },
      ],
    },
  ],
};

export default SecurityRoutes;
