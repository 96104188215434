import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

// const SurveyFormList = SuspenseWrapper(
//   lazy(() => import("components/survey-form/FormList"))
// );
// const SurveyForm = SuspenseWrapper(lazy(() => import("components/survey-form/AddForm")));

const SurveyRoutes = {
  path: "survey-form",
  element: <Outlet />,
  // children: [
  //   {
  //     path: "list",
  //     element: <SurveyFormList />,
  //   },
  //   {
  //     path: "add",
  //     element: <SurveyForm />,
  //   },
  //   {
  //     path: "edit/:hash",
  //     element: <SurveyForm />,
  //   },
  // ],
};

export default SurveyRoutes;
