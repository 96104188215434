import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reportOrder: []
};

export const reportOrderSlice = createSlice({
  name: "reportOrder",
  initialState,
  reducers: {
    setReportOrder: (state, action) => {
      state.reportOrder = action.payload;
    },
  },
});

export const { setReportOrder } = reportOrderSlice.actions;
export default reportOrderSlice.reducer;
