import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const Posts = SuspenseWrapper(lazy(() => import("components/posts/v2/OverAllView")));
const PostForm = SuspenseWrapper(lazy(() => import("components/posts/v2/PostForm")));
const OverviewForm = SuspenseWrapper(lazy(() => import("components/posts/v2/PostOverView")));

const PostsRoutes = {
  path: "posts",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <Posts mode="P" tab=""/>,
    },
    {
      path: "add",
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <PostForm pageMode="P"  tab=""/>,
          </Suspense>
      ),
    },
    {
      path: "overview",
      element: <OverviewForm pageMode="P" tab=""/>
    },
    

  ],
};

export default PostsRoutes;
