import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const Communication = SuspenseWrapper(lazy(() => import("components/communication/Communication")));
const OverviewForm = SuspenseWrapper(lazy(() => import("components/posts/v2/PostOverView")));
const PostForm=SuspenseWrapper(lazy(() => import("components/posts/v2/PostForm")))
const SmsForm = SuspenseWrapper(lazy(() => import("components/sms/send-v4/SmsForm")));
const WhatsappForm = SuspenseWrapper(lazy(() => import("components/whatsapp/send/WhatsappForm")));
const HomeworkForm = SuspenseWrapper(lazy(() => import("components/activity/AddHomeWork")));
const ActivitySimpleAdd = SuspenseWrapper(lazy(() => import("components/activity/AddSimpleHomeWork")));
const ActivityDayStatusForm = SuspenseWrapper(lazy(() => import("components/activity/ActivityDayStatusForm")));
const ActivitySimpleSettings = SuspenseWrapper(lazy(() => import("components/activity/ActivitySimpleSettings")));
const CommunicationRoutes = {
  path: "communication",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <Communication pageMode = "P" tab="C"/>
        </Suspense>
      ),
    },
    {
      path: "posts",
      element: <Outlet />,
      children: [
        {
          path: "add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <PostForm pageMode="P" tab = "C" />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "posts",
      element: <Outlet />,
      children: [
        {
          path: "overview",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <OverviewForm pageMode="P" tab = "C"/>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "sms",
      element: <Outlet />,
      children: [
        {
          path: "add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <SmsForm pageMode="P" tab = "C" />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "whatsapp",
      element: <Outlet />,
      children: [
        {
          path: "add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <WhatsappForm pageMode="P" tab = "C"/>
            </Suspense>
          ),
    
        },
      ]
    },
    {
      path: "activity",
      element: <Outlet />,
      children: [
        {
          path: "add",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <HomeworkForm tab = "C"/>
            </Suspense>
          ),
    
        },
      ]
    },
    {
      path: "activity",
      element: <Outlet />,
      children: [
        {
          path: "dayStatus",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <ActivityDayStatusForm  tab = "C"/>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "activity",
      element: <Outlet />,
      children: [
        {
          path: "simple-homework",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <ActivitySimpleAdd  tab = "C"/>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "activity",
      element: <Outlet />,
      children: [
        {
          path: "simple-settings",
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <ActivitySimpleSettings  tab = "C"/>
            </Suspense>
          ),
        },
      ],
    },
  ],
};
export default CommunicationRoutes;
