import { createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    list: [],
  },
  reducers: {
    setMenuList(state, action) {
      state.list = action.payload;
    },
  },
});

export const { setMenuList } = menuSlice.actions;
export default menuSlice.reducer;