import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StaffProfileForm = SuspenseWrapper(lazy(() => import("components/staff-profile/StaffprofileForm")));

const StaffProfileRoutes = {
    path: "staffprofile",
    element: <Outlet />,
    children: [
      
      {
        path: "details",
        element: <StaffProfileForm />,
      },

    ],
  };
  
  export default StaffProfileRoutes;