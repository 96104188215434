import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";
// import PostActivityAttendanceOverView from "components/posts/postActivityAttendanceOverview";

const PostActivityAttendanceOverView =SuspenseWrapper(lazy(()=>import('components/posts/postActivityAttendanceOverview')));
const WhatsappForm = SuspenseWrapper(lazy(() => import("components/communication/whatsapp/send/WhatsappForm")));
const PostAttActRoutes = {
  path: "post_att_act_overview",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <PostActivityAttendanceOverView />,
    },
]
  
};
export default PostAttActRoutes;
