import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const Posts = SuspenseWrapper(lazy(() => import("components/posts/v2/OverAllView")));
const PostForm = SuspenseWrapper(lazy(() => import("components/posts/v2/PostForm")));


const ComponentForm = SuspenseWrapper(lazy(() => import("components/payroll/ComponentForm")));
const ComponentList = SuspenseWrapper(lazy(() => import("components/payroll/ComponentList")));
const StaffPayRollList = SuspenseWrapper(lazy(() => import("components/payroll/StaffList")));
const StaffCompForm = SuspenseWrapper(lazy(() => import("components/payroll/StaffCompForm")));
const PayrunList = SuspenseWrapper(lazy(() => import("components/payroll/PayrunList")));
const PayrunForm = SuspenseWrapper(lazy(() => import("components/payroll/PayrunForm")));
const PayrunStaffList = SuspenseWrapper(lazy(() => import("components/payroll/PayrunStaffList")));
const StaffOverAllView = SuspenseWrapper(lazy(() => import("components/payroll/report/OverAllView")));
const PayStructureList = SuspenseWrapper(lazy(() => import("components/payroll/PayStructureList")));
const PayStructureForm = SuspenseWrapper(lazy(() => import("components/payroll/PayStructureForm")));

const PayrollRoutes = {
  path: "payroll",
  element: <Outlet />,
  children: [
    {
      path: "comp",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ComponentList />,
        },{
          path: "add",
          element: <ComponentForm />,
        },{
          path: "edit/:hash",
          element: <ComponentForm />,
        },
      ]
    },{
      path: "staff-list",
      element: <StaffPayRollList />,
    },{
      path: "comp-mapping/:hash",
      element: <StaffCompForm />,
    },{
      path: "payrun",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <PayrunList />,
        },{
          path: "add",
          element: <PayrunForm />,
        },{
          path: "edit/:hash",
          element: <PayrunForm />,
        },{
          path: "staff-list/:hash",
          element: <PayrunStaffList />,
        },
      ]
    },{
      path: "pay-structure",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <PayStructureList />,
        },{
          path: "add",
          element: <PayStructureForm />,
        },{
          path: "edit/:hash",
          element: <PayStructureForm />,
        },
      ]
    },{
      path: "report",
      element: <Outlet />,
      children: [
        {
          path: "staff/:hash",
          element: <StaffOverAllView />,
        }
      ]
    }    
  ],
};

export default PayrollRoutes;
