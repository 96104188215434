import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ScholarGroupList = SuspenseWrapper(
  lazy(() => import("components/scholar-group/ScholarGroupList"))
);
const ScholarGroupForm = SuspenseWrapper(
  lazy(() => import("components/scholar-group/ScholarGroupForm"))
);
const ScholarGroupScholarList = SuspenseWrapper(
  lazy(() => import("components/scholar-group/ScholarGroupScholarList"))
);
const ScholarGroupView = SuspenseWrapper(
  lazy(() => import("components/scholar-group/ScholarGroupView"))
);

const ScholarGroupRoutes = {
  path: "scholar-group",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <ScholarGroupList />,
    },
    {
      path: "add",
      element: <ScholarGroupForm />,
    },
    {
      path: "edit/:hash",
      element: <ScholarGroupForm />,
    },
    {
      path: "scholarlist/:hash",
      element: <ScholarGroupScholarList />,
    },
    {
      path: "view/:hash",
      element: <ScholarGroupView />,
    },
  ],
};

export default ScholarGroupRoutes;
