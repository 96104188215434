import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StaffAttList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/att-entry/AttendanceList"))
);
const StaffAttEntry = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/att-entry/AttendanceEntry"))
);
const StaffAttStatusList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/StatusList"))
);
const StaffAttShiftList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/ShiftList"))
);
const StaffAttShiftForm = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/ShiftForm"))
);
const StaffAttGroupList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/GroupList"))
);
const StaffAttGroupForm = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/GroupForm"))
);
const StaffAttAccountList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/AttStaffList"))
);
const StaffAttAccountForm = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/AttStaffForm"))
);
const StaffAttOverrideShiftList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/ExceptionList"))
);
const StaffAttEntryView = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/att-entry/AttendanceEntryView"))
);
const StaffAttMonthlyView = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/att-entry/AttendanceMonthView"))
);
const AttWithOutList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/AttWithOutList"))
);
const StaffBiometricList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/StaffBiometricList"))
);
const StaffAttMonthList = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/StaffAttMonthList"))
);
const StaffAttPunchINReport = SuspenseWrapper(
  lazy(() => import("components/staff-attendance/StaffAttPunchINReport"))
);
const StaffAttendanceRoutes = {
  path: "staff-attendance",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <StaffAttList />,
    },
    {
      path: "register/:hash",
      element: <StaffAttEntry />,
    },
    {
      path: "status",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffAttStatusList />,
        },
      ],
    },
    {
      path: "shift",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffAttShiftList />,
        },
        {
          path: "add",
          element: <StaffAttShiftForm />,
        },
        {
          path: "edit/:hash",
          element: <StaffAttShiftForm />,
        },
      ],
    },
    {
      path: "group",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffAttGroupList />,
        },
        {
          path: "add",
          element: <StaffAttGroupForm />,
        },
        {
          path: "edit/:hash",
          element: <StaffAttGroupForm />,
        },
      ],
    },
    {
      path: "account",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffAttAccountList />,
        },
        {
          path: "add",
          element: <StaffAttAccountForm />,
        },
        {
          path: "edit/:hash",
          element: <StaffAttAccountForm />,
        },
      ],
    },
    {
      path: "override-shift",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffAttOverrideShiftList />,
        },
      ],
    },
    {
      path: "view/:hash",
      element: <StaffAttEntryView />,
    },
    {
      path: "monthview",
      element: <StaffAttMonthlyView />,
    },
    {
      path: "attwithoutlist",
      element: <AttWithOutList />,
    },
    {
      path: "staffattmonthlist",
      element: <StaffAttMonthList />,
    },
    {
      path: "staffBiometricList",
      element: <StaffBiometricList />,
    },
    {
      path: "staffpunchinreports",
      element: <StaffAttPunchINReport />,
    },
  ],
};

export default StaffAttendanceRoutes;
