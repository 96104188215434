import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StaffContactsList = SuspenseWrapper(
  lazy(() => import("components/contacts/StaffContactsList"))
);

const ContactsRoutes = {
  path: "contacts",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <StaffContactsList />,
    },
  ],
};

export default ContactsRoutes;
