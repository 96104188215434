import { createRoot } from "react-dom/client";
import "./index.css";

import MainApp from "./MainApp";

import { Provider } from "react-redux";

import { store } from "./app/store";
//import AppUpdate from "./AppUpdate";
import ThemeWrapper from "ThemeWrapper";
import Hardreload from "components/Hardreload";

const root = createRoot(document.getElementById("root"));
root.render(
  <ThemeWrapper>
    <Provider store={store}>
      {/* <AppUpdate> */}
        <Hardreload>
        <MainApp />
        </Hardreload>
      {/* </AppUpdate> */}
    </Provider>
  </ThemeWrapper>
);
