import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const IsMobileNoMaskedSlice = createSlice({
  name: "IsMobileNoMasked",
  initialState,
  reducers: {
    setIsMobileNoMasked: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setIsMobileNoMasked } = IsMobileNoMaskedSlice.actions;
export default IsMobileNoMaskedSlice.reducer;
