import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TabView = SuspenseWrapper(lazy(() => import("components/quotesPost/TabView")));
const StreamOverAllView = SuspenseWrapper(lazy(() => import("components/quotesPost/StreamOverAllView")));
const OverAllView = SuspenseWrapper(lazy(() => import("components/quotesPost/OverAllView")));
const ExeOverAllView = SuspenseWrapper(lazy(() => import("components/quotesPost/ExeOverAllView")));
const DayPostForm = SuspenseWrapper(lazy(() => import("components/quotesPost/PostForm")));
const StreamForm = SuspenseWrapper(lazy(() => import("components/quotesPost/StreamForm")));
const QuotesPostImportStatusList = SuspenseWrapper(lazy(() => import("components/quotesPost/QuotesPostImportStatusList")));
const QuotesPostImportForm = SuspenseWrapper(lazy(() => import("components/quotesPost/QuotesPostImportForm")));
const QuotesPostImportList = SuspenseWrapper(lazy(() => import("components/quotesPost/QuotesPostImportList")));

const QuotesRoutes = {
  path: "quotes",
  element: <Outlet />,
  children: [
    {
      path: "stream",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <StreamOverAllView />,
        },
        {
          path: "add",
          index: true,
          element: <StreamForm />,
        },
        {
          path: "edit/:hash",
          index: true,
          element: <StreamForm />,
        },
      ]
    },
    {
      path: "posts",
      element:<Outlet />,
      children: [
        {
          path: "list/:hash",
          index: true,
          element: <OverAllView />,
        },
        {
          path: "add/:hash",
          index: true,
          element: <DayPostForm />,
        },
        {
          path: "edit/:hash",
          index: true,
          element: <DayPostForm />,
        },
      ]
    },{
      path: "exe-quotes",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <ExeOverAllView />,
        },
      ]
    },
    {
      path: "import-list",
      element: <QuotesPostImportList />,
    },
    {
      path: "import",
      element: <QuotesPostImportForm />,
    },
    {
      path: "import-status/:hash",
      element: <QuotesPostImportStatusList />,
    },
  ],
};

export default QuotesRoutes;
