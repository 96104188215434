import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const SopPosts = SuspenseWrapper(lazy(() => import("components/sop/OverAllView")));
const SopPostForm = SuspenseWrapper(lazy(() => import("components/sop/PostForm")));
const SopCategoryList = SuspenseWrapper(
  lazy(() => import("components/sop/SopCategoryList"))
);
const SopPermissionList = SuspenseWrapper(
  lazy(() => import("components/sop/SopPermissionList"))
);
const SopPostPermissionForm = SuspenseWrapper(
  lazy(() => import("components/sop/SopPostPermissionForm"))
);
const SopRoutes = {
  path: "sopposts",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <SopPosts/>,
    },
    {
      path: "add",
      element: <SopPostForm/>,
    },
    {
      path: "edit/:hash",
      element: <SopPostForm />,
    },
    {
      path: "category/list",
      element: <SopCategoryList />,
    },
    {
      path: "permission/list",
      element: <SopPermissionList />,
    },
    {
      path: "staffpermission",
      element: <Outlet />,
      children: [
        {
          path: "add/:hash",
          element: <SopPostPermissionForm />,
        },
      ],
    },
  ],
};

export default SopRoutes;
