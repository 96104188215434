import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TransportFeeStructureList = SuspenseWrapper(
  lazy(() => import("components/transport/FeeStructureList"))
);
const TransportFeeStructureStep = SuspenseWrapper(
  lazy(() => import("components/transport/FeeStructureStep"))
);
const TransportFeeStructureTermList = SuspenseWrapper(
  lazy(() => import("components/transport/FeeStructureTermList"))
);
const TransportFeeStructureTermForm = SuspenseWrapper(
  lazy(() => import("components/transport/FeeStructureTermForm"))
);
const TransportFeeStructureView = SuspenseWrapper(
  lazy(() => import("components/transport/FeeStructureView"))
);

const TransportFeeRoutes = {
  path: "transport",
  element: <Outlet />,
  children: [
    {
      path: "feestructure",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportFeeStructureList />,
        },
        {
          path: "add",
          element: <TransportFeeStructureStep />,
        },
        {
          path: "edit/:hash",
          element: <TransportFeeStructureStep />,
        },
        {
          path: "view/:hash",
          element: <TransportFeeStructureView />,
        },
      ],
    },
    {
      path: "feeterm",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportFeeStructureTermList />,
        },
        {
          path: "add",
          element: <TransportFeeStructureTermForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportFeeStructureTermForm />,
        },
      ],
    },
  ],
};

export default TransportFeeRoutes;
