import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const AuditLogList = SuspenseWrapper(lazy(() => import("components/audit_log/AuditLogList")));
const AuditLogActionList = SuspenseWrapper(lazy(() => import("components/audit_log/AuditLogActionList")));

const AuditLogRoutes = {
  path: "audit-log",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <AuditLogList/>,
    },
    {
      path: "action-list",
      element: <AuditLogActionList/>,
    }  
  ],
};

export default AuditLogRoutes;
